#shadow {
  position: absolute;
  width: 100%;
  height: auto;
  img {
    width: 100%;
    vertical-align: top;
  }
}
.shadow {
  position: relative;
  width: 100vw;
  padding: 0;
  z-index: -1;
  margin: 0;
  top: 0;
  > .embed-responsive {
    position: relative;
    height: auto;
  }
  img {
    width: calc(100%);
    vertical-align: top;
  }

  .optimizedpicture {
    position: relative;
    width: 100%;
    height: 100%;

    picture {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
      }
    }
  }
}
.shadow-top {
  position: absolute;
  width: 100%;
  left: 0;
  img {
    vertical-align: bottom !important;
    max-width: 100%;
    width: 100%;
  }
}
.shadow-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  top: unset;
  //transform: translateY(100%);
  img {
    vertical-align: top;
    max-width: 100%;
  }
}

.shadow-outside {
  transform: translateY(-100%);
}

.shadow-outside-bottom {
  transform: translateY(100%);
}
.shadow-above-bottom-bottom {
  top: 1px;
  position: relative;
  img {
    max-width: 100%;
    width: 100%;
    vertical-align: top;
  }
}