@function flexAlign($var) {
  $erg: center !default;

  @if $var == 'left' {
    $erg: flex-start;
  }

  @if $var == 'right' {
    $erg: flex-end;
  }

  @if $var == 'justify' {
    $erg: space-around;
  }

  @return $erg;
}

@function flexVerticalAlign($var) {
  $erg: center !default;

  @if $var == 'top' {
    $erg: flex-start;
  }

  @if $var == 'bottom' {
    $erg: flex-end;
  }

  @if $var == 'justify' {
    $erg: stretch;
  }

  @return $erg;
}

$nav__ul__li__a--text-shadow: none !default;
$nav__ul__li__dropdown--box-shadow: 0px 0px 15px rgba(0,0,0,0.1) !default;
$nav__ul__li__dropdown--border-radius: 5px !default;
$nav__ul__li__dropdown--border: 0px !default;
$nav__ul__li__dropdown__a--link-hover-bg: #ff0000 !default;
$nav__ul__active__a--bg: none !default;

nav {
  background: $header__bottom-area__nav--bg;
  #mobile-menu {
    display: none;
    .icon {
      width: 24px;
      height: 24px;

      .icon-menu {
        fill: $green;
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0px 0px 5px 0px;
    overflow: visible;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flexAlign($nav__ul--content-horizontal-align);
    align-items: flexVerticalAlign($nav__ul--content-vertical-align);
    width: 100%;

    .active {
      a {
        color: $nav__ul__active__a--color;
        background: $nav__ul__active__a--bg;
      }

      a:before {
        background-color: transparent !important;
      }
    }

    li {
      margin-left: 30px;
      position: relative;
      overflow: visible;

      .dropdown-toggle::after {
        content: "";
        display: none;
      }

      a {
        background-color: rgba(0, 0, 0, 0);
        box-sizing:border-box;
        color: $nav__ul__li__a--color;
        cursor:pointer;
        display:block;
        font-size: $nav__ul__li__a--font-size;
        font-style:normal;
        font-family: $nav__ul__li__a--font-family;
        font-weight: $nav__ul__li__a--font-weight;
        height:48px;
        line-height:55px;
        opacity:1;
        position:relative;
        text-align:left;
        text-decoration: none;
        text-shadow: $nav__ul__li__a--text-shadow;
        transition: opacity 0.24s ease-in-out;
        visibility:visible;
        -webkit-font-smoothing:antialiased;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        white-space: nowrap;
        transition: 0.3s color ease;

        img {
          max-width: unset !important;
        }
      }

      a:hover {
        color: $nav__ul__li__a--link-hover-color;
      }

      a:before {
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        right: 0;
        transform: scaleX(0);
        transition: all 0.3s ease 0s;
        bottom: 0px;
      }
    }

    .nav__ul > li:hover {
      .sub-menu {
        visibility: visible;
        opacity: 1;

        li > a:before {
          opacity: 0;
        }
      }
      a {
        color: $nav__ul__li__a--link-hover-color;
        text-decoration: none;
        visibility: inherit;
        opacity: 1;
      }
      a:before {
        transform: scaleX(1);
      }
    }

    .active {
      a {
        color: $nav__ul__li__a--link-active-color;
      }
      a:before {
        transform: scaleX(1);
        background-color: $nav__ul__li__a--link-active-color;
        bottom: 0px;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        right: 0;
        transition: all 0.3s ease 0s;
      }
    }


    .dropdown-menu {
      background: $nav__ul__li__dropdown--bg;
      box-shadow: $nav__ul__li__dropdown--box-shadow;
      border-radius: $nav__ul__li__dropdown--border-radius;
      border: $nav__ul__li__dropdown--border;
      left: -40px;

      a {
        font-weight: 600;
        font-style: normal;
        color: $nav__ul__li__dropdown__a--color;
        line-height: unset;
        height: unset;
        font-size: $nav__ul__li__dropdown__a--font-size;
        font-style:normal;
        font-family: $nav__ul__li__dropdown__a--font-family;
        font-weight: $nav__ul__li__dropdown__a--font-weight;
        text-align: $nav__ul__li__dropdown__a--text-align;
      }

      a:hover {
        color: $nav__ul__li__dropdown__a--link-hover-color;
        background: $nav__ul__li__dropdown__a--link-hover-bg;
      }

      a:before {
        height: 0px;
      }
    }
  }
}

