$header__logo--bg: #fff000 !default;
$header__logo--padding: 15px 18px;

.header > .logo {
  position: fixed;
  background: $header__logo--bg;
  background-size: $header__logo--bg-size;
  padding: $header__logo--padding;
  z-index: 99;
  width: $header__logo--width;
  height: $header__logo--height;

  img {
    height: auto;
    left: 50%;
    margin-left: 2px;
    margin-top: 2px;
    position: relative;
    transform: translateX(-50%);
    width: 90px;
  }
}

.header > .logo-center {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.header > .logo-left {
  position: fixed;
  top: 0px;
  left: 0px;
}

.header > .logo-right {
  position: fixed;
  top: 0px;
  right: 0px;
}