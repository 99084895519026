$main--padding: 10px 0 40px !default;
$main--bg: #fff !default;
$main--font-size: $body--font-size !default;

$main__container--border-width: 0px 0px 0px 0px !default;
$main__container--border-style: solid !default;
$main__container--margin: 0px 0px 0px 0px !default;
$main__container--border-color: #ccc !default;


$main__a--color: #00ff00 !default;
$main__a--font-weight: bold !default;

$main__a__hover--color: #ff0000 !default;
$main__a__hover--text-decoration: none !default;

$main__textblock-devider--border-bottom: #ff00ff !default;
$main__textblock-devider--background: #00ff00 !default;

main {
  position: relative;
  width: 100%;
  padding: $main--padding;
  background: $main--bg;
  z-index: 80;
  min-height: 200px;
  font-size: $main--font-size;

  #main__content {
    z-index: 60;
    .main__content__ajax {
      padding-bottom: 0px;
    }
  }

  .container .container {
    border: $main__container--border-width $main__container--border-style $main__container--border-color;
    margin: $main__container--margin;
    padding-top: 20px;
  }

  a {
    font-weight: $main__a--font-weight;
    color: $main__a--color;
    &:hover, &:focus, &:active {
      color: $main__a__hover--color;
      text-decoration: $main__a__hover--text-decoration;
    }
  }
}