//<editor-folding desc="### FOOTER ###">

$footer--bg: #999 !default;
$footer--height: auto !default;
$footer--color: #fff !default;
$footer--font-family: 'Arial' !default;
$footer--font-size: 20px !default;
$footer--font-weight: 300 !default;
$footer--height: auto !default;
$footer--min-height: 200px !default;
$footer--padding: 20px 0px 20px 0px !default;
$footer--text-shadow: none !default;
$footer--text-align: left !default;


$footer__a--color: #ccc !default;
$footer__a--text-shadow: none !default;
$footer__a--font-weight: bold !default;
$footer__a__hover--color: #ddd !default;


$footer__footerinhalt--margin: 0px !default;
$footer__footerinhalt--padding: 0px !default;

$footer__h2--color: #000 !default;
$footer__h2--font-family: 'Arial' !default;
$footer__h2--font-size: 30px !default;
$footer__h2--font-weight: 200 !default;
$footer__h2--line-height: auto !default;
$footer__h2--margin: 0 !default;
$footer__h2--text-align: center !important;
$footer__h2--text-shadow: none !default;
$footer__h2--text-rendering: auto !default;

$footer__h3--color: #000 !default;
$footer__h3--font-family: 'Arial' !default;
$footer__h3--font-size: 20px !default;
$footer__h3--font-weight: 200 !default;
$footer__h3--line-height: auto !default;
$footer__h3--margin: 0 !default;
$footer__h3--text-align: center !important;
$footer__h3--text-shadow: none !default;

$footer__socialmediaiocons--margin: 0px 0px 0px 10px !default;

$footer__balken-bottom--background: #000 !default;
$footer__balken-bottom--color: #fff !default;
$footer__balken-bottom--text-align: center !default;
$footer__balken-bottom--padding: 20px 0px !default;
$footer__balken-bottom--font-size: 10px !default;
$footer__balken-bottom--font-family: Arial !default;
$footer__balken-bottom--text-shadow: none !default;

$footer__balken-bottom2--background: #666 !default;
$footer__balken-bottom2--color: #fff !default;
$footer__balken-bottom2--text-align: center !default;
$footer__balken-bottom2--padding: 20px 0px !default;
$footer__balken-bottom2--font-size: 10px !default;
$footer__balken-bottom2--font-family: Arial !default;

/**
  * FOOTER
 */
footer {
  background: $footer--bg;
  color: $footer--color;
  font-family: $footer--font-family;
  font-size: $footer--font-size;
  font-weight: $footer--font-weight;
  height: $footer--height;
  min-height: $footer--min-height;
  padding: $footer--padding;
  position: relative;
  text-align: $footer--text-align;
  text-shadow: $footer--text-shadow;
  width: 100%;
  z-index: 90;
  a {
    color: $footer__a--color;
    text-shadow: $footer__a--text-shadow;
    font-weight: $footer__a--font-weight;
    &:hover, &:focus {
      color: $footer__a__hover--color;
      text-decoration: none;
    }
  }
  .footerinhalt {
    margin: $footer__footerinhalt--margin;
    padding: $footer__footerinhalt--padding;
  }

  h2 {
      color: $footer__h2--color;
      font-size: $footer__h2--font-size;
      font-family: $footer__h2--font-family;
      font-weight: $footer__h2--font-weight;
      margin: $footer__h2--margin;
      text-align: $footer__h2--text-align;
      text-shadow: $footer__h2--text-shadow;
      line-height: $footer__h2--line-height;
      text-rendering: $footer__h2--text-rendering;
  }

  h3 {
    color: $footer__h3--color;
    font-size: $footer__h3--font-size;
    font-family: $footer__h3--font-family;
    font-weight: $footer__h3--font-weight;
    margin: $footer__h3--margin;
    text-align: $footer__h3--text-align;
    text-shadow: $footer__h3--text-shadow;
    line-height: $footer__h3--line-height;
  }

  .schatten-footer {
    vertical-align: bottom;
    width: 100%;
    transform: translateY(-100%);
    position: absolute;
    top:0;
    img {
      vertical-align: bottom;
      width: 100%;
    }
  }

  .socialmediaicons {
    margin: $footer__socialmediaiocons--margin;
  }

  .balken-bottom {
    background: $footer__balken-bottom--background;
    color: $footer__balken-bottom--color;
    text-align: $footer__balken-bottom--text-align;
    padding: $footer__balken-bottom--padding;
    font-size: $footer__balken-bottom--font-size;
    font-family: $footer__balken-bottom--font-family;
    text-shadow: $footer__balken-bottom--text-shadow;
  }

  .balken-bottom2 {
    background: $footer__balken-bottom2--background;
    color: $footer__balken-bottom2--color;
    text-align: $footer__balken-bottom2--text-align;
    padding: $footer__balken-bottom2--padding;
    font-size: $footer__balken-bottom2--font-size;
    font-family: $footer__balken-bottom2--font-family;
  }

  @include media-breakpoint-down(md) {
    .allgemeineinfos {
      li {

        text-align: center;
      }
    }
    #compo-footer {
      display: none;
    }
    .footer__icons a i {
      margin-right: 16px !important;
    }
  }

  .row .col-md-4 {
  padding-bottom: 20px;
  }
}
//</editor-folding>
