@import "import";

//noinspection CssUnknownTarget
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700|Raleway:300,400,600|Just+Me+Again+Down+Here:400');


/**
  FARBEN
 */

$dunkelblau: #003056;
$hellblau: #20537b;
$extremhellblau: #5f91b8;
$hell-braun: #003056;

/**
  GENERAL
 */

$body--font-family: 'Raleway';
$body--font-size: 18px;
$body--font-weight: 300;
$body--font-color: #333;

$h1--color: $dunkelblau;
$h1--font-family: 'Roboto';
$h1--line-height: 42px;
$h1--text-transform: none;
$h1--font-size: 50px;
$h1--font-weight: 100;
$h1--padding: 0px 0px 5px 0px;
$h1--text-shadow: 1px 1px 0px #fff;


$h2--color: $h1--color;
$h2--font-family: $h1--font-family;
$h2--line-height: $h1--line-height;
$h2--text-transform: $h1--text-transform;
$h2--font-size: $h1--font-size;
$h2--font-weight: $h1--font-weight;
$h2--padding: $h1--padding;
$h2--text-shadow: $h1--text-shadow;

$h3--color: $hell-braun;
$h3--font-family: 'Raleway';
$h3--font-size: 24px;
$h3--font-weight: 600;
$h3--padding: 0px 0px 10px 0px;
$h3--text-shadow: 1px 1px 0px #fff;

$font-size-h1: 48px;
$font-shadow-h1: 1px 1px 0 #fff;
$font-color-h1: #bb2731;
$font-weight-h1: 500;

$font-size-h2: 18px;
$font-color-h2: #8e1a22;
$font-margin-h2: 0 0 10px 0;
$font-weight-h2: 700;

$font-color-h3: #bdd042;
$font-margin-h3: 4px 0 0 0;


$header-bg-verlauf-enabled: false;
$header--bg: #321c0c;
$header--height: 173px;

$header__top-area--height: 125px;
$header--bg: $dunkelblau;

$header__top-area__svg--color: $hellblau;
$header__logo--bg: url(../images/logo_head_bg.png) !important;
$header__logo--width: 150px;
$header__logo--height: 140px;
$header__logo--bg-size: 100% !important;

$header__bottom-area__nav--bg: none;

$main--padding: $header--height 0px 0px 0px;


$nav__ul__li__a--font-family: 'Roboto';
$nav__ul__li__a--color: #ffffff !important;
$nav__ul__active__a--color: $extremhellblau !important;
$nav__ul__active__a--bg: none;
$nav__ul__li__a--link-hover-color: $hellblau !important;
$nav__ul__li__a--font-size: 21px;
$nav__ul__li__a--font-weight: 300;
$nav__ul__li__a--text-shadow: none;


$nav__ul__li__dropdown--bg: rgba(255, 255, 255, 0.75);
$nav__ul__li__dropdown__a--font-family: 'Raleway';
$nav__ul__li__dropdown__a--font-weight: 300;
$nav__ul__li__dropdown__a--font-size: 18px;
$nav__ul__li__dropdown__a--color: #321c0c !important;
$nav__ul__li__dropdown__a--link-hover-color: #977351 !important;
$nav__ul__li__dropdown__a--link-hover-bg: rgba(255,255,255,0.5) !important;
$nav__ul__li__dropdown__a--text-align: center !important;

$navbar-default-link-color: #da6ca3;
$navbar-link-hover-color: #321c0c;

$flexslider__control-nav__active--color: #da6ca3;
$flexslider__control-nav__normal--color: #321c0c;
$flexslider__control-nav__hover--color: #d3b395;

$main__a--color: $hellblau;
$main__a__hover--color: $hell-braun;



$footer__h2--color: #fff;
$footer__h2--font-family: 'Roboto';
$footer__h2--font-size: $h2--font-size;
$footer__h2--font-weight: $h2--font-weight;
$footer__h2--line-height: $h2--line-height;
$footer__h2--text-rendering: geometricPrecision;
$footer__h2--text-shadow: 0 -1px ;


$footer__h3--color: #fff;
$footer__h3--font-family: $h3--font-family;
$footer__h3--font-weight: $h3--font-weight;

$footer__h3--font-size: $h3--font-size;
$footer__h3--text-shadow: 0px;

$footer__a--color: #fff;
$footer__a__hover--color: $extremhellblau;

$footer--font-family: 'Raleway';
$footer--font-weight: 300;
$footer--font-size: 15px;
$footer--padding: 40px 0px 0px 0px;

$footer--bg: $dunkelblau;
$footer__balken-bottom--background: $hellblau;
$footer__balken-bottom--color: $hell-braun;
$footer__balken-bottom--font-family: 'Raleway';

$footer__balken-bottom2--padding: 10px;
$footer__balken-bottom2--background: $hell-braun;


$linkboxen--box-shadow: 0px 0px 20px rgba(50, 28, 12, 0.1);
$linkboxen__name--background: rgba(255, 255, 255, 0.7);
$linkboxen__imgdiv--border: 1px solid #fcfbf7;
$linkboxen__a--color: $dunkelblau;
$linkboxen__a--font-family: Roboto;
$linkboxen__a--font-size: 30px;
$linkboxen__a--line-height: 50px;
$linkboxen__a--font-weight: 300;

$linkboxen__a-hover--font-size: 40px !default;
$linkboxen__a-hover--line-height: 60px !default;

$news--startseite-font-size: 15px;
$news--startseite-content-padding: 0;
$news--startseite-border-radius: 0px;
$news--startseite-container-margin: 40px 0 60px;
$news--startseite-width: unquote("calc(33% - 10px)");
$news--startseite-padding: 10px;
$bnews--startseite-media-img-container: true;

/**
 GENERAL IMPORT
 */
@import "variables";
@import "general/functions";
@import "general/basics";
@import "header/navigation";
@import "header/header";
@import "header/logo";
@import "content/content";
@import "general/schatten";
@import "general/footer";
@import "teaser/teaser";
@import "teaser/flexslider";
@import "general/fontawesome/fontawesome";
$fa-font-path: '../fonts/fontawesome';


@import "general/fontawesome/fa-regular";
@import "general/fontawesome/fa-brands";
@import "general/fontawesome/fa-solid";
@import "modules/textblock";
@import "addons/news";
@import "addons/calendar";

@import "../css/test.css";

.header {
  display: flex;
  justify-content: center;
  .header__top-area {
    padding: 0px;
    margin: 0px;
    border-bottom: 1px solid $hellblau;

    .header__top-area__title {
      font-family: Roboto;
      text-transform: uppercase;
      .first {
        font-weight: 400;
        color: $extremhellblau;
        font-size: 47px;
        margin-top: 10px;
        line-height: 45px;
        font-family: "Just Me Again Down Here";
      }
      .second {
        font-weight: 400;

        color: #ffffff;
        font-size: 30px;
        font-family: "Just Me Again Down Here";
      }
    }
  }
}
.header__top-area__bg {
  position: absolute;
  right: 0px;
  top: 0px;
  overflow: hidden;
}

.header__top-area__row {
  height: $header__top-area--height;
  width: calc(100%);
}

.header__bottom-area {
  position: absolute;
  top: 125px;
  left: 50%;
  transform: translateX(-50%) translateY(0%) !important;

  nav {
    padding: 0px !important;
    transform: translateY(0%) !important;
    ul {
      border-radius: 5px;
      padding: 0px !important;
      li {
        margin-left: 0px;
        .active {
          a {
            padding: 0px 10px;
          }
        }
        a {
          padding: 0px 10px;
        }
      }
    }
  }
}

.textblock-devider {
  background: none !important;

  background-size: 100% !important;
  background-repeat: no-repeat !important;
  height: 30px !important;
  width: calc(100% + 30px) !important;
  position: relative;
  margin: 0px !important;
  left: -15px;
  border: 0px !important;
  bottom: -20px !important;

  img {
    width: 100%;
    vertical-align: bottom !important;
    bottom: 0px;
    position: absolute;
  }

}

article {
  padding: 20px;
}

.linkboxen {
  margin-top: 40px;
  .linkbox__name {
    div {
      text-shadow: 0.5px 0.5px 0px #fff;
    }
  }
}

#main__content {
  @include gradient-y(#ebdbc6, #fcfbf7, 0%, 100%);
  padding-top: 1px;
  position: relative;
}

.footer__icons {
  font-size: 60px;
  display: flex;

  a {
    flex-grow: 1;
    text-align: right;

    i {
      margin-right: -13px;
    }
  }
}

  body {
    margin-top: -4px;
    font-size: 17px;
    background: #e5e5e5;
    color: #402a1a;
  }

@include media-breakpoint-down(md) {
  .header {
    height: 125px !important;

    .logo {
      height: auto;
      bottom: -28px;
      position: absolute;
      top: unset;
      left: 74px;
      img {
        width: 101px;
      }
    }
    .header__top-area {
      .header__top-area__icons {
        top: 8px !important;
        div {
          a {
            i {
              color: $hellblau;
            }
          }
        }
      }
    }
    .header__bottom-area {
      top: 15px;
      z-index: 99999999;
    }
  }

  #main__content {
    .main__content__ajax {
      .container-fluid {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  #main {
    padding-top: 125px !important;
  }

  .header {
    .shadow {
      display: none;
    }
  }

  .header__top-area__title {
    z-index: 9;
    position: relative;
    padding: 0px 15px 15px 4px;
  }

  .header__top-area__bg {
    z-index: 10;
    img {
      -webkit-filter: drop-shadow(5px 5px 5px #222 );
      filter: drop-shadow(5px 5px 5px #222);
    }
  }

  .countdown {
    width: 100%;
    padding: 0px 20px;
    z-index: 98;

    .countdown-date {
      right: 12px !important;
    }
  }

  .navbar-toggler {
    background: rgba(255,255,255, 0.8);
  }


  .navbar-collapse {
    background: rgba(0, 48, 86, 0.95);
    border-radius: 15px;
    box-shadow: 0px 0px 5px #000;
  }

  .flexslider__nav {
    display: none !important;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 16px;
  }
}


.rexshop__list__article--title {
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: rgba(255,255,255, 0.7);
}

.shoppingcart--items {
  position: absolute;
  top: 16px;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  line-height: 5px;
  font-size: 11px;
}

.countdown {
  position: absolute;
  right: 0;
  top: 102px;
  color: #fff;
  font-weight: 100;
  font-family: Roboto;
  font-size: 12px;
  height: 25px;
  width: 430px;

  .countdown-name {
    position: absolute;
    right: 170px;
    width: 300px;
    text-align: right;
    bottom: 5px;
  }

  .countdown-date {
    position: absolute;
    right: 0px;
    width: 170px;
    text-align: right;
    font-size: 12px;
    bottom: 0px;
    padding-right: 5px;
    span {
      font-size: 18px;
      margin-right: 5px;
    }
  }
}

.map {
  position: relative;
  height: 100%;
  min-height: 400px;
  margin: 30px 0 0;
  padding: 0 0 113px;

  #map {
    height: 100%;
    margin-bottom: 0 !important;
    padding-bottom: 0;
    width: 100%;
    min-height: 400px;
    border: 1px solid silver;
  }
}
.downloads {
  max-width: 600px;
  .download__file {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 5px;
    .download__file--icon {
      flex-basis: 50px;
    }

    .download__file--name {
      width: auto;
      flex-basis: 100%;
    }

    .download__file--size {
      width: 140px;
    }

    .download__file--link {
    }
  }

  .download__file:hover {
    background: rgba(255,255,255, 0.5);
  }
}


.myevents-container {
  display: flex;

  .myevents-list {
    list-style-type: none;
    width: 100%;
    position: relative;
    .myevents-wrapper {
      width: 100%;
      background: rgba(255,255,255,0.7);
      padding: 15px;
      margin: 15px;
      .myevent-dates {
        display: none;
      }
    }
  }
}

.galler5_pic {
  margin: 10px 0px;
}
.optimizedpicture {
  position: relative;
  padding-top: 100%;
  picture {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      max-width: 100%;
      height: 100%;
    }
  }
}

.gallery5 {
  img {
    box-shadow: 0px 0px 5px #3c3c3c;
  }
}