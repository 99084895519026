/* line 3, ../scss/modules/_calendar.scss */
.mini-calendar hr {
  color: #cccccc;
  margin: 10px 0;
}
/* line 7, ../scss/modules/_calendar.scss */
.mini-calendar .mini-calendar--header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
/* line 9, ../scss/modules/_calendar.scss */
.mini-calendar .mini-calendar--header > div {
  width: calc(100% / 7 - 5px);
  margin-left: 5px;
  font-weight: 700;
}
/* line 14, ../scss/modules/_calendar.scss */
.mini-calendar .mini-calendar--header :nth-child(1) {
  margin-left: 0;
}
/* line 18, ../scss/modules/_calendar.scss */
.mini-calendar .mini-calendar--days {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
/* line 22, ../scss/modules/_calendar.scss */
.mini-calendar .mini-calendar--days > div {
  width: calc(100% / 7 - 5px);
  height: 30px;
  background: $hellblau;
  margin-top: 5px;
  margin-left: 5px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* line 33, ../scss/modules/_calendar.scss */
.mini-calendar .mini-calendar--days > div.weekday-1 {
  margin-left: 0;
}
/* line 36, ../scss/modules/_calendar.scss */
.mini-calendar .mini-calendar--days > div.day-empty {
  background: none;
}
/* line 39, ../scss/modules/_calendar.scss */
.mini-calendar .mini-calendar--days > div.weekday-6, .mini-calendar .mini-calendar--days > div.weekday-7 {
  background: $dunkelblau;
}
/* line 43, ../scss/modules/_calendar.scss */
.mini-calendar .mini-calendar--days :nth-child(1) {
  margin-left: 0;
}

/* line 48, ../scss/modules/_calendar.scss */
.next-termine {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* line 51, ../scss/modules/_calendar.scss */
.next-termine hr {
  margin: 10px 0;
}
/* line 54, ../scss/modules/_calendar.scss */
.next-termine .next-termin {
  word-break: break-all;
  font-size: 14px;
  margin: 5px 0;
}
/* line 58, ../scss/modules/_calendar.scss */
.next-termine .next-termin .next-termin--datum {
  color: $dunkelblau;
  font-weight: 700;
}

/* line 65, ../scss/modules/_calendar.scss */
.termin-verlinkung {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* line 70, ../scss/modules/_calendar.scss */
.termin-verlinkung hr {
  width: 100%;
  height: 1px;
  top: 50%;
}
/* line 75, ../scss/modules/_calendar.scss */
.termin-verlinkung img {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* line 86, ../scss/modules/_calendar.scss */
.calendar-quartal {
  padding: 20px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  background: #fff;
  margin: 20px 0;
}
/* line 89, ../scss/modules/_calendar.scss */
.calendar-quartal hr {
  color: #cccccc;
  margin: 10px 0;
}
/* line 93, ../scss/modules/_calendar.scss */
.calendar-quartal .calendar-quartal--listing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
/* line 98, ../scss/modules/_calendar.scss */
.calendar-quartal .calendar-quartal--termine {
  width: 100%;
}
/* line 100, ../scss/modules/_calendar.scss */
.calendar-quartal .calendar-quartal--termine .termin-row {
  text-align: center;
}
/* line 102, ../scss/modules/_calendar.scss */
.calendar-quartal .calendar-quartal--termine .termin-row .datum {
  color: #76ad00;
  font-weight: 700;
  text-align: center;
}
/* line 106, ../scss/modules/_calendar.scss */
.calendar-quartal .calendar-quartal--termine .termin-row .datum .uhrzeit {
  font-weight: 400;
}

/* line 113, ../scss/modules/_calendar.scss */
.calendar-monat {
  width: 280px;
  padding: 10px 19px 20px;
}
/* line 116, ../scss/modules/_calendar.scss */
.calendar-monat h4 {
  color: #888888;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 10px;
}
