$body--font-family: 'Raleway' !default;
$body--font-size: 18px !default;
$body--font-weight: normal !default;
$body--font-color: #333 !default;
$body--text-align: left !default;

$h1--color: #000 !default;
$h1--font-family: 'Roboto' !default;
$h1--font-size: 50px !default;
$h1--font-weight: bold !default;
$h1--line-height: auto !default;
$h1--margin: 0px !default;
$h1--padding: 5px 0px 10px 0px !default;
$h1--text-align: center !default;
$h1--text-shadow: none !default;
$h1--text-transform: uppercase !default;

$h2--color: #666 !default;
$h2--font-family: 'Roboto' !default;
$h2--font-size: 24px !default;
$h2--font-weight: bold !default;
$h2--line-height: auto !default;
$h2--margin: 0px !default;
$h2--padding: 5px 0px 10px 0px !default;
$h2--text-align: center !default;
$h2--text-shadow: none !default;
$h2--text-transform: none !default;

$h3--color: #999 !default;
$h3--font-family: 'Roboto' !default;
$h3--font-size: 20px !default;
$h3--font-weight: bold !default;
$h3--line-height: auto !default;
$h3--margin: 0px !default;
$h3--padding: 5px 0px 10px 0px !default;
$h3--text-align: center !default;
$h3--text-shadow: none !default;
$h3--text-transform: none !default;

$h4--color: #aaa !default;
$h4--font-family: 'Roboto' !default;
$h4--font-size: 19px !default;
$h4--font-weight: bold !default;
$h4--line-height: auto !default;
$h4--margin: 0px !default;
$h4--padding: 5px 0px 10px 0px !default;
$h4--text-align: center !default;
$h4--text-shadow: none !default;
$h4--text-transform: none !default;

$h5--color: #000 !default;
$h5--font-family: 'Roboto' !default;
$h5--font-size: 50px !default;
$h5--font-weight: bold !default;
$h5--line-height: auto !default;
$h5--margin: 0px !default;
$h5--padding: 5px 0px 10px 0px !default;
$h5--text-align: center !default;
$h5--text-shadow: none !default;
$h5--text-transform: none !default;

$h6--color: #000 !default;
$h6--font-family: 'Roboto' !default;
$h6--font-size: 50px !default;
$h6--font-weight: bold !default;
$h6--line-height: auto !default;
$h6--margin: 0px !default;
$h6--padding: 5px 0px 10px 0px !default;
$h6--text-align: center !default;
$h6--text-shadow: none !default;
$h6--text-transform: none !default;

$line-height-h1: $line-height-base !default;
$line-height-h2: $line-height-base !default;
/**
    BASE
 */
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: $body--font-family;
  font-size: $body--font-size;
  font-weight: $body--font-weight;
  font-color: $body--font-color;
  text-align: $body--text-align;
}
a {
  -webkit-transition: background-color .4s linear, color .4s linear;
  -moz-transition: background-color .4s linear, color .4s linear;
  -o-transition: background-color .4s linear, color .4s linear;
  transition: background-color .4s linear, color .4s linear;
  &:focus {
    border: 0;
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
}

// HEADINGS
h1 {
  color: $h1--color;
  font-family: $h1--font-family;
  font-size: $h1--font-size;
  font-weight: $h1--font-weight;
  line-height: $h1--line-height;
  margin: $h1--margin;
  padding: $h1--padding;
  text-align: $h1--text-align;
  text-shadow: $h1--text-shadow;
  text-transform: $h1--text-transform;
  text-rendering: geometricPrecision;
}
h2 {
  color: $h2--color;
  font-family: $h2--font-family;
  font-size: $h2--font-size;
  font-weight: $h2--font-weight;
  line-height: $h2--line-height;
  margin: $h2--margin;
  padding: $h2--padding;
  text-align: $h2--text-align;
  text-shadow: $h2--text-shadow;
  text-transform: $h2--text-transform;
}
h3 {
  color: $h3--color;
  font-family: $h3--font-family;
  font-size: $h3--font-size;
  font-weight: $h3--font-weight;
  line-height: $h3--line-height;
  margin: $h3--margin;
  padding: $h3--padding;
  text-align: $h3--text-align;
  text-shadow: $h3--text-shadow;
  text-transform: $h3--text-transform;
}
h4 {
  color: $h4--color;
  font-family: $h4--font-family;
  font-size: $h4--font-size;
  font-weight: $h4--font-weight;
  line-height: $h4--line-height;
  margin: $h4--margin;
  padding: $h4--padding;
  text-align: $h4--text-align;
  text-shadow: $h4--text-shadow;
  text-transform: $h4--text-transform;
}
h5 {
  color: $h5--color;
  font-family: $h5--font-family;
  font-size: $h5--font-size;
  font-weight: $h5--font-weight;
  line-height: $h5--line-height;
  margin: $h5--margin;
  padding: $h5--padding;
  text-align: $h5--text-align;
  text-shadow: $h5--text-shadow;
  text-transform: $h5--text-transform;
}
h6 {
  color: $h6--color;
  font-family: $h6--font-family;
  font-size: $h6--font-size;
  font-weight: $h6--font-weight;
  line-height: $h6--line-height;
  margin: $h6--margin;
  padding: $h6--padding;
  text-align: $h6--text-align;
  text-shadow: $h6--text-shadow;
  text-transform: $h6--text-transform;
}


// Trennlinien
.hline {
  @include hline;
  width: 100%;
  height: 0;
}

//Bootstrap Row mit gleicher Hoehe
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

picture {
  width: 100%;
  height: 100%;
}

.navbar {
  justify-content: flex-end;
}
