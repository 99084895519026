$flexslider__control-nav__active--color: #ff00ff !default;
$flexslider__control-nav__normal--color: #0000ff !default;
$flexslider__control-nav__hover--color: #00ff00 !default;
.flexslider__nav {
  display: flex;

  .flex-direction-nav {
    z-index: 80;
  }

  .flexslider__nav__container {
    position: absolute;
    bottom: 0px;
    z-index: 91;
  }

  .flex-control-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 30px;
    width: 100vw;
    align-self: flex-end;
    z-index: 90;

    li {
      width: 25px;
      text-align: center;
      margin: 0 6px;
      display: inline-block;
      zoom: 1;
      *display: inline;

      .flex-active {
        color: $flexslider__control-nav__active--color;
        i {
          font-weight: 900;
        }
      }

      a {
        width: 25px;
        text-align: center;
        color: $flexslider__control-nav__normal--color;
      }

      a:hover {
        color: $flexslider__control-nav__hover--color;
      }
    }
  }
}