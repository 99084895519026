.news-termine--container {
  background: #d0bca2;
  position: relative;
  padding: 30px 0px 50px 0px;
  margin: 0px;

  h1 {
    font-size: 50px;
    color: #fff;
    text-shadow: -1px -1px #a58965;
    font-family: "GrundschriftLight";
  }
  h2 {
    color: #507500;
    font-size: 30px;
    text-shadow: 1px 1px 0 #eadfd0;
  }

  .news-termine-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .news-termine-row {
    .news-row {
      .news {
        margin: 0px;
        padding: 10px 10px 20px 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        background: #fff;

        h4 {
          color: #003056;
          font-family: 'Just Me Again Down Here', cursive;
          font-size: 34px;
          min-height: 55px;
          line-height: 27px;
          font-weight: 400;
          margin: 0px;
          padding: 0px;
        }

        .news--media {
          margin: 10px 0;
          position: relative;
        }

        .news--media:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 75%;
        }

        .news--media-img-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;

          .optimizedpicture {
            width: 100%;
            height: 100%;
            picture {
              img {
                max-width: unset;
                height: 100%;
              }
            }
          }
        }

        .news--content {
          font-family: "Raleway", sans-serif;
          font-size: 14px;
          text-align: left;
        }

        .news--verlinkung {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-top: auto;

          hr {
            width: 100%;
            height: 1px;
            top: 50%;
          }

          img {
            position: absolute;
            top: 50%;
            width: 40px;
            height: 40px;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }
        }
      }
      .news, .termine {
        text-align: center;
        background: #fff;
        -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
      }

      .termine {
        width: 100%;
        padding: 10px 19px 20px;
        height: 100%;
        background: #fff;
        h4 {
          color: #003056;
          font-family: 'Just Me Again Down Here', cursive;
          font-size: 34px;
          min-height: 55px;
          line-height: 27px;
          font-weight: 400;
          margin: 0px;
          padding: 0px;
        }
      }
    }
  }
  .trenner {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #bea27e;
    border-bottom: 1px solid #eadfd0;
  }
}



.news-seite {
  h2 {
    margin-bottom: 40px;
  }

  .smallpic {
    margin: 10px 0px 10px 0px;
  }

  .news-image {
    img {
      box-shadow: 0px 0px 10px #3c3c3c;
      border: 10px solid #ffffff;
    }
  }
}