$textblock-devider--border-bottom: 1px solid #f0f !default;
$textblock-devider--background: #0f0 !default;

article {
  .textblock-devider {
    height: 2px;
    width: calc(100% - 30px);
    border-bottom: $textblock-devider--border-bottom;
    background: $textblock-devider--background;
    margin: 15px 15px 30px 15px;
  }
  .textblock-image-align-center {
    display: flex;
    justify-content: center;
  }

  .textblock-image-align-right {
    display: flex;
    justify-content: flex-end;
  }

  .textblock-image-align-left {
    display: flex;
    justify-content: flex-start;
  }
}

.textblock-image {
  margin-bottom: 20px;
  img {
    width: auto;
    position: relative;
    max-width: 100%;
  }

  .optimizedpicture {
    padding-top: 100%;
    position: relative;
    width: 100%;
    height: 100%;

    picture {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.textblock-image-align-center {
  picture {
    text-align: center;
  }
}